var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import '../Css/style.css';
import Lenis from '@studio-freight/lenis';
import { Fancybox } from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox/fancybox.css";
import Rellax from 'rellax';
import * as AOS from "aos";
import barba from '@barba/core';
import gsap from 'gsap';
AOS.init();
AOS.refresh();
if (window.innerWidth >= 768) {
    var rellax = new Rellax('.rellax', {
        center: true
    });
}
window.addEventListener('resize', function () {
    if (window.innerWidth <= 768) {
        if (rellax) {
            rellax.destroy();
        }
    }
});
//Fancybox for Lightbox usage
Fancybox.bind('[data-fancybox="gallery"]', {});
//Lenis for Smooth Scroll
document.addEventListener('DOMContentLoaded', function (event) {
    var lenis = new Lenis({
        duration: 1.3,
        easing: function (t) { return Math.min(1, 1.001 - Math.pow(2, -10 * t)); },
    });
    function raf(time) {
        lenis.raf(time);
        requestAnimationFrame(raf);
    }
    requestAnimationFrame(raf);
    document.querySelectorAll('a[href^="#"]').forEach(function (anchor) {
        if (anchor.getAttribute('href') !== '#') {
            anchor.addEventListener('click', function (e) {
                e.preventDefault();
                lenis.scrollTo(this.getAttribute('href'));
                var target = document.querySelector(this.getAttribute('href'));
                target.setAttribute('tabindex', '-1');
                target.focus({ preventScroll: true, focusVisible: false });
                target.addEventListener('blur', function () {
                    target.removeAttribute('tabindex');
                });
                target.addEventListener('focusout', function () {
                    target.removeAttribute('tabindex');
                });
            });
        }
    });
    var btt = document.querySelector('.back-to-top');
    if (btt) {
        btt.addEventListener('click', function () {
            lenis.scrollTo('top');
        });
    }
});
//end of Lenis
// Navigation Opener
var hasSubMenus = document.querySelectorAll('.has-sub');
hasSubMenus.forEach(function (hasSubMenu) {
    var subMenu = hasSubMenu.querySelector('.sub-menu');
    if (subMenu) {
        hasSubMenu.addEventListener('click', function () {
            subMenu.classList.toggle('open');
        });
    }
});
//scroll checker
function addScrollDirection(element) {
    var lastScrollTop = 0;
    document.addEventListener('scroll', function (e) {
        var scrollTop = window.scrollY || document.documentElement.scrollTop;
        if (scrollTop > lastScrollTop) {
            element.classList.add('scrolling-down');
            element.classList.remove('scrolling-up');
        }
        else if (scrollTop != lastScrollTop) {
            element.classList.remove('scrolling-down');
            element.classList.add('scrolling-up');
        }
        lastScrollTop = scrollTop <= 0 ? 0 : scrollTop;
    });
}
addScrollDirection(document.body);
//scroll class for navigation
var navigationScrollEvent = function (navigation) {
    var checkScroll = function () {
        var top = window.pageYOffset || document.documentElement.scrollTop;
        if (top > 1) {
            navigation.classList.add("scrolling");
        }
        else {
            navigation.classList.remove("scrolling");
        }
    };
    checkScroll();
    window.addEventListener('scroll', checkScroll);
};
document.addEventListener('DOMContentLoaded', function () {
    var navigation = document.getElementById('navigation');
    if (navigation) {
        navigationScrollEvent(navigation);
    }
});
function pageTransition() {
    var tl = gsap.timeline();
    tl.to('.transition-barba-full', { duration: .5, scaleY: 1, transformOrigin: "bottom", ease: "power4.inOut" });
    tl.to('.transition-barba-full', { duration: .5, scaleY: 0, transformOrigin: "top", ease: "power4.inOut", delay: .2 });
    // tl.to('ul.transition-barba li', { duration: .5, scaleX: 1, transformOrigin: "top right", stagger: .2 });
    // tl.to('ul.transition-barba li', { duration: .5, scaleX: 0, transformOrigin: "top right", stagger: .1, delay: .1 });
}
function contentAnimation() {
    var tl = gsap.timeline();
    tl.to('.transition-barba-full', { duration: .5, scaleY: 1, transformOrigin: "bottom", ease: "power4.inOut" });
    tl.to('.transition-barba-full', { duration: .5, scaleY: 0, transformOrigin: "top", ease: "power4.inOut", delay: .2 });
    // tl.to('ul.transition-barba li', { duration: .5, scaleX: 1, transformOrigin: "top right", stagger: .2 });
    // tl.to('ul.transition-barba li', { duration: .5, scaleX: 0, transformOrigin: "top right", stagger: .1, delay: .1 });
}
function delay(n) {
    n = n || 0;
    return new Promise(function (done) {
        setTimeout(function () {
            done(undefined);
        }, n);
    });
}
barba.init({
    transitions: [{
            leave: function (data) {
                return __awaiter(this, void 0, void 0, function () {
                    var done;
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                done = this.async();
                                pageTransition();
                                return [4 /*yield*/, delay(500)];
                            case 1:
                                _a.sent();
                                done();
                                return [2 /*return*/];
                        }
                    });
                });
            },
            enter: function (data) {
                return __awaiter(this, void 0, void 0, function () {
                    return __generator(this, function (_a) {
                        contentAnimation();
                        return [2 /*return*/];
                    });
                });
            },
            once: function (data) {
                return __awaiter(this, void 0, void 0, function () {
                    return __generator(this, function (_a) {
                        contentAnimation();
                        return [2 /*return*/];
                    });
                });
            }
        }]
});
var countersec = document.querySelectorAll('.number');
var observer = new IntersectionObserver(function (entries) {
    entries.forEach(function (entry) {
        if (entry.isIntersecting) {
            var animate_1 = function () {
                var value = +entry.target.getAttribute('data-count');
                var data = +entry.target.innerText;
                var time = value / 100;
                if (data < value) {
                    entry.target.innerText = Math.ceil(data + time).toString();
                    setTimeout(animate_1, 50);
                }
            };
            animate_1();
        }
    });
}, { threshold: 0.6 });
countersec.forEach(function (counter) {
    observer.observe(counter);
});
// const counters = document.querySelectorAll('.number');
// const speed = 1000;
// counters.forEach( counter => {
//      const animate = () => {
//             const value = +counter.getAttribute('data-count');
//             const data = +(<HTMLElement>counter).innerText;
//             const time = value / speed;
//          if(data < value) {
//                     (<HTMLElement>counter).innerText = Math.ceil(data + time).toString();
//                     setTimeout(animate, 1);
//                 }else{
//                     (<HTMLElement>counter).innerText = value.toString();
//                 }
//      }
//      animate();
// });
